import LoadingOverlay from 'components/LoadingOverlay';
import React from 'react';
import { RequiredTableDataProps, TableColumn } from 'components/renewaled_ui/Table/interface/table';
import { Table } from 'components/renewaled_ui/Table';
import { TableToolBar } from "components/renewaled_ui/DataFetchingTable/molecules/TableToolBar";
import { useHooks } from './hooks';

interface Props<Data> {
  /** テーブルの列情報 */
  readonly columns: TableColumn[];
  /** 列の並び順 */
  readonly columnOrder?: TableColumn['accessor'][];
  /**
   * DataFetchTableで扱うデータ配列
   * 全データ数分の配列、データ未取得のものはundefinedで埋める
   */
  readonly data: (Data | undefined)[];
  /** 1ページ当たりの表示件数 */
  readonly sizePerPage: number;
  /** 1ページ当たりの表示件数の選択肢 */
  readonly sizePerPageList: number[];
  /** 1ページ当たりの表示件数を隠すか */
  readonly hideSizePerPage?: boolean;
  /** 現在のページ */
  readonly currentPage: number;
  /** データ読み込み中か */
  readonly isLoading?: boolean;
  /** 行のチェックボックスを表示するか */
  readonly showCheckBoxes?: boolean;
  /** 列幅変更するか */
  readonly columnResizable?: boolean;
  /** 詳細閲覧中データ */
  readonly viewingData?: Data;
  /** テーブルボディーのfont-weight */
  readonly bodyFontWeight?: 400 | 500 | 600;
  /**
   * テーブルの最大高さ
   * いろんな指定方法が想定されるので、cssのプロパティをそのまま文字列指定
   * maxHeighを超えた場合、ヘッダー固定でスクロールが発生する
   */
  readonly maxHeight?: string;
  /** ヘッダーを表示するか(default true) */
  readonly showHeader?: boolean;
  /** 行をクリックした際の遷移先(指定した場合aリンクになる) */
  readonly href?: string;
  /**
   * テーブル左下の１ページあたりのデータ取得結果を表示するか
   * 一部APIで、クエリで指定する検索結果の数と実際に返ってくる数に差がある場合があり、
   * 合わない箇所については暫定的に結果の数を隠せる(falseにする)ためのprops
   */
  readonly showItemsCount?: boolean;
  /** 行のドラッグ&ドロップを有効にするか */
  readonly useRowDragAndDrop?: boolean;
  /**
   * ページネーションした時に呼び出されるデータを取得するための関数
   * @param startIndex 開始ページ 0から始まる
   * @param sizePerPage データ取得数
   * @param background バックグラウンドでデータ取得するか
   * @param sortName ソート名
   * @param sortOrder ソート順
   */
  readonly fetchData?: (startIndex: number, sizePerPage: number, background: boolean, sortName?: string, sortOrder?: 'ASC' | 'DESC') => Promise<void>;
  /** 1ページ当たりの表示件数を変更した際のハンドラ */
  readonly onSizePerPageList: (sizePerPage: number) => void;
  /** ページ移動時のハンドラ */
  readonly onPageChange: (page: number) => void;
  /** ソート実行時のハンドラ */
  readonly onSortChange?: (sortName: string, sortOrder: 'ASC' | 'DESC') => Promise<void>;
  /** 行のチェックボックスを選択した際のハンドラ */
  readonly onSelect?: (data: Data, isSelected: boolean) => void;
  /** ページ内の全行のチェックボックスを選択した際のハンドラ */
  readonly onSelectAll?: (isSelected: boolean) => void;
  /** 選択した項目の操作ドロップダウン内に表示するDOM */
  readonly renderSelectedItemsControlMenu?: () => JSX.Element;
  /** 列の幅変更時のハンドラ */
  readonly onColumnWidthChange?: (id: string, width: number) => void;
  /** 行をクリックした際のハンドラ */
  readonly onRowClick?: (data: Data) => void;
  /** 行移動時のハンドラ */
  readonly onMoveRow?: (dragIndex: number, targetIndex: number) => Promise<void>;
  /**
   * 枠線がないシンプルなデザイン
   * TODO: テーブルデザインが一つになるので消える
   */
  readonly isSimpleDesignMode?: boolean;
  /**
   * 請求書刷新でのテーブルデザイン
   * TODO: テーブルデザインが一つになるので消える
   */
  readonly isReportItemDesignMode?: boolean;
}

/**
 * データ取得機能付きテーブル
 * ページングやソートがあるテーブルで使用する
 */
export const DataFetchingTable = <Data extends RequiredTableDataProps, >({
  columns,
  columnOrder,
  data,
  fetchData,
  sizePerPage,
  onSizePerPageList,
  sizePerPageList,
  hideSizePerPage,
  currentPage,
  isLoading,
  onPageChange,
  onSortChange,
  showCheckBoxes,
  columnResizable,
  onSelect,
  onSelectAll,
  renderSelectedItemsControlMenu,
  onColumnWidthChange,
  onRowClick,
  viewingData,
  bodyFontWeight,
  maxHeight,
  showHeader = true,
  href,
  showItemsCount = true,
  useRowDragAndDrop,
  onMoveRow,
  isSimpleDesignMode = true,
  isReportItemDesignMode = false,
}: Props<Data>): JSX.Element => {
  const {
    isBackgroundLoading,
    handleSortChange,
    paginationSize,
    handlePageChange,
    selectedItemsLength,
    currentPageData,
    isShowPagination,
  } = useHooks(data, currentPage, sizePerPage, onPageChange, isLoading, onSortChange, fetchData);

  return (
    <LoadingOverlay
      show={ isLoading && !isBackgroundLoading }
      iconClassName={ 'fa fa-spinner fa-spin fa-fw fa-3x' }
    >
      <Table
        data={ currentPageData }
        columns={ columns }
        columnOrder={ columnOrder }
        onSortChange={ handleSortChange }
        showCheckBoxes={ showCheckBoxes }
        columnResizable={ columnResizable }
        onSelect={ onSelect }
        onSelectAll={ onSelectAll }
        onColumnWidthChange={ onColumnWidthChange }
        onRowClick={ onRowClick }
        viewingData={ viewingData }
        bodyFontWeight={ bodyFontWeight }
        maxHeight={ maxHeight }
        isLoading={ isLoading && !isBackgroundLoading }
        showHeader={ showHeader }
        href={ href }
        useRowDragAndDrop={ useRowDragAndDrop }
        onMoveRow={ onMoveRow }
        isSimpleDesignMode={ isSimpleDesignMode }
        isReportItemDesignMode={ isReportItemDesignMode }
      />

      <TableToolBar
        isSimpleDesignMode={ isSimpleDesignMode }
        isShowPagination={ isShowPagination() }
        isReportItemDesignMode={ isReportItemDesignMode }
        sizePerPage={ sizePerPage }
        paginationSize={ paginationSize }
        currentPage={ currentPage }
        hideSizePerPage={ hideSizePerPage }
        showItemsCount={ showItemsCount }
        sizePerPageList={ sizePerPageList }
        selectedItemsLength={ selectedItemsLength }
        renderSelectedItemsControlMenu={ renderSelectedItemsControlMenu }
        onPageChange={ handlePageChange }
        onSizePerPageList={ onSizePerPageList }
        currentPageDataLength={ currentPageData.length }
        dataLength={ data.length }
      />
    </LoadingOverlay>
  );
};
